import React, {Component} from "react";
import Headerhome from "../components/Headerhome";
import ProjectteaserHome from "../components/ProjectteaserHome";
import Footer from "../components/Footer";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet"

export default class Home extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Hi, I’m Fanny! UX/UI designer and front-end developer from Ghent!</title>
          </Helmet>
          <GatsbySeo noindex={true} />
          <div className="page">
            <Headerhome />
            <ProjectteaserHome />
            <Footer />
          </div>
      </div>  
    );
  }
}