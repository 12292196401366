import React from "react";
import IpvImgHome from "../images/projects/ipv.jpg";
import biocheckImgHome from "../images/projects/biocheck.jpg";
import Blocktitle from "./Blocktitle";
import '../styles/components/projectshome.scss';
import { Link } from "gatsby";
import {motion} from "framer-motion";

const ProjectHome = props => (
    <Link to={props.projecthomelink} className="projects--wrapper">
      <div className="project--img">
        <motion.img whileHover={{ scale: 1.03 }} transition={{ ease: "easeInOut" }} data-src={props.projecthomeimg} className="lazyload" alt={props.projecthomename} />
      </div>
      <div className="project--description">
        <h3 className="project--name">{props.projecthomename}</h3>
        <p className="project--function">{props.projecthomeexcerpt}</p>
      </div>
    </Link>
)

export default () => (
    <div className="projects--home container">
      <Blocktitle blockTitle="Selected projects" />
      <div className="projects--home--wrapper">
        <ProjectHome
            projecthomelink="/projects/ipvdiergeneeskunde"
            projecthomename="IPV Diergeneeskunde"
            projecthomeimg={IpvImgHome}
            projecthomeexcerpt="UX/UI design, front-end development"
        />
        <ProjectHome
            projecthomelink="/projects/biocheck"
            projecthomename="Biocheck.UGent"
            projecthomeimg={biocheckImgHome}
            projecthomeexcerpt="UX/UI design, front-end development"
        />
      </div>
    </div>
)