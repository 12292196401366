import React from "react";
import { Link } from "gatsby";
import UncontrolledLottieHome from '../components/UncontrolledLottieHome';
import Pagetitle from "./Pagetitle";
import Bodytext from "./Bodytext";
import MainMenu from "./Mainmenu";
import '../styles/components/headerhome.scss';

export default () => (
  <header className="site--header home container">
    <Link to="/" className="lotties">
      <UncontrolledLottieHome />
    </Link>
    <div className="menu-block--wrapper">
      <MainMenu />
      <Pagetitle pageTitle="Hi, I’m Fanny! UX/UI designer and front-end developer from Ghent!" />
      <Bodytext bodyText="I’m currently available for new career opportunities. Please check out my about me and projects pages. Questions? Don’t hesitate to contact me!" />
    </div>

  </header>
)