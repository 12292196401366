import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/logo-animation.json';

class UncontrolledLottie extends Component {
  render(){
    const defaultOptions = {
      renderer: "canvas",
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return(
      <div className="lottie">
        <Lottie options={defaultOptions}
                height={450}
                width={450}
        />
      </div>
    )
  }
}

export default UncontrolledLottie